import React from 'react';
import { createIframe } from 'cookie-toss';

const App = () => {
	// Sites allows to access data on the hub domain:
	const dependentDomains = ['.buyontrust.com', 'buyontrust.info', 'localhost:3000'];
	// Sets up the contents of the iframe:
	createIframe({ dependentDomains });

	return <div />
}

export default App;
